<template>
  <UiTooltip v-if="properties" :disabled :name="`${tagCode}_${id}`" :width="200">
    <template #activator>
      <div
        class="text-caption flex flex-row items-center gap-1 rounded-md p-1 leading-4"
        :class="[properties.class, { '!rounded-sm': size === 'sm' && !showText }]"
      >
        <div :class="sizeClassesMap.get(size)?.iconSize">
          <UiIcon
            :id="`${tagCode}_${id}`"
            :name="properties.icon"
            class="!size-full"
            :class="sizeClassesMap.get(size)?.padding"
          />
        </div>

        <span v-if="showText">{{ properties.text }}</span>
      </div>
    </template>
    <template #content>{{ properties.tooltip }}</template>
  </UiTooltip>
</template>

<script setup lang="ts">
import type { LeadTagUiProperties } from '@/types'

type Props = {
  id: number | string
  tagCode: string
  properties: LeadTagUiProperties
  showText?: boolean
  size?: string
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  size: 'md',
})

const sizeClassesMap = new Map([
  [
    'xs',
    {
      iconSize: '!h-4 !w-4',
      padding: '!p-0.5',
    },
  ],
  [
    'sm',
    {
      iconSize: '!h-5 !w-5',
      padding: '!p-0.5',
    },
  ],
  [
    'md',
    {
      iconSize: '!h-7 !w-7',
      padding: '!p-1.5',
    },
  ],
])
</script>

<style scoped></style>
